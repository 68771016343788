import Image from "next/image";
import Link from "next/link";
import Typography from "../../../components/Typography";
import { ImageContainer } from "../styled";

export default function ProductAndService({ image, title, content }) {
  return (
    <Link passHref href={image.href}>
      <a>
        {image.src && (
          <ImageContainer>

            <Image alt="Product" {...image} />
          </ImageContainer>
        )}
        <Typography
          textAlign="center"
          color="darkGray2"
          fontWeight="bold"
          fontSize="1.6rem"
        >
          {title}
        </Typography>
        <Typography fontWeight="bold" textAlign="center">
          {content}
        </Typography>
      </a>
    </Link>
  );
}
